import { navigate } from '@reach/router'
import { Link, withPrefix } from 'gatsby'
import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import AuthContext from '../../context/auth-context'
import MobileHeader from './MobileHeader'
import {
  DropDownContent,
  DropDownLi,
  Dropbtn,
  MenuContainer,
  StyledBWLogo,
  StyledContainer,
  StyledCustomLinkMenu,
  StyledHeader,
  StyledItemMenu,
  StyledLinkMenu,
  StyledList,
} from './style'

const LogoBW = require('../../assets/images/general/logo-bw.svg')
const FacebookIcon = require('../../assets/images/general/icon-facebook.svg')
const InstagramIcon = require('../../assets/images/general/icon-instagram.svg')

interface Props {
  type: string
  toggleModal: () => void
  currentPage?: string
}

const authPages: any[] = ['login', 'forgot-password']

class Header extends Component<Props, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      menuOpen: false,
      isSubmenuOpened: false,
    }
  }

  render() {
    const { currentPage, type, toggleModal } = this.props
    return (
      <StyledHeader className={authPages.includes(currentPage) ? 'no-background-img' : ''} type={type} id="header-id">
        <StyledContainer className="container">
          <div className="row">
            <StyledBWLogo className="col-md-2 col-6" type={type} onClick={() => navigate(withPrefix('/'))}>
              <img src={LogoBW} />
            </StyledBWLogo>
            <MobileHeader toggleModal={toggleModal} />

            <MenuContainer className="col-sm-10">
              <StyledList className={currentPage === 'home' ? 'centered' : 'end'}>
                <StyledItemMenu>
                  <StyledLinkMenu className="GTABLE_MENU" data-menu="Home" selected={currentPage === 'home'} to="/">
                    <span>Home</span>
                  </StyledLinkMenu>
                </StyledItemMenu>
                {process.env.WITH_BURGER_FEST === 'active' ? (
                  <DropDownLi>
                    <Dropbtn>Eventos</Dropbtn>
                    <DropDownContent>
                      <StyledLinkMenu className="GTABLE_MENU dropdown-link" data-menu="Events" selected={currentPage === 'events'} to="/events">
                        <span>Eventos del mes</span>
                      </StyledLinkMenu>
                      <StyledLinkMenu className="GTABLE_MENU dropdown-link" data-menu="Events" selected={currentPage === 'events'} to="/events/burger-fest">
                        <span>Burger fest</span>
                      </StyledLinkMenu>
                    </DropDownContent>
                  </DropDownLi>
                ) : (
                  <StyledItemMenu>
                    <StyledLinkMenu className="GTABLE_MENU" data-menu="Events" selected={currentPage === 'events'} to="/events">
                      <span>Eventos</span>
                    </StyledLinkMenu>
                  </StyledItemMenu>
                )}
                <StyledItemMenu>
                  <StyledLinkMenu className="GTABLE_MENU" data-menu="Membresías" selected={currentPage === 'membership'} to="/membership">
                    <span>Membresías</span>
                  </StyledLinkMenu>
                </StyledItemMenu>
                <StyledItemMenu>
                  <StyledLinkMenu className="GTABLE_MENU" data-menu="Chefs" selected={currentPage === 'chefs'} to="/chefs">
                    <span>Chef en casa</span>
                  </StyledLinkMenu>
                </StyledItemMenu>
                <ReactTooltip id="experiences" place="bottom" className="customeTip" effect="solid" clickable={true} delayHide={125}>
                  <ul>
                    <li>
                      <Link className="GTABLE_MENU" data-menu="Chefs" to="/chefs">
                        Chef en casa
                      </Link>
                    </li>
                    <li>
                      <Link className="GTABLE_MENU" data-menu="Eventos" to="/events">
                        Eventos
                      </Link>
                    </li>
                  </ul>
                </ReactTooltip>
                <StyledItemMenu>
                  <StyledLinkMenu className="GTABLE_MENU" data-menu="Beneficios" selected={currentPage === 'benefits'} to="/benefits">
                    <span>Beneficios</span>
                  </StyledLinkMenu>
                </StyledItemMenu>
                <StyledItemMenu>
                  <StyledLinkMenu className="GTABLE_MENU" data-menu="FAQ" selected={currentPage === 'faq'} to="/faq">
                    <span>FAQ</span>
                  </StyledLinkMenu>
                </StyledItemMenu>
                <StyledItemMenu>
                  <StyledCustomLinkMenu className="GTABLE_MENU" data-menu="Contacto" onClick={() => toggleModal()}>
                    <span>Contacto</span>
                  </StyledCustomLinkMenu>
                </StyledItemMenu>
                <StyledItemMenu className={authPages.includes(currentPage) ? 'hidden' : ''}>
                  <a id="GTABLE_FB" href="https://www.facebook.com/gtclubgourmet" target="_blank" rel="noreferrer">
                    <img src={FacebookIcon} alt="Grande Table Facebook" />
                  </a>
                </StyledItemMenu>
                <StyledItemMenu className={authPages.includes(currentPage) ? 'hidden' : ''}>
                  <a id="GTABLE_INST" href="https://www.instagram.com/grandetableclubgourmet/" target="_blank">
                    <img src={InstagramIcon} alt="Grande Table Instagram" />
                  </a>
                </StyledItemMenu>
                <StyledItemMenu className={authPages.includes(currentPage) ? 'hidden' : ''}>
                  <AuthContext.Consumer>
                    {(context: any) => {
                      return context.purchase ? (
                        <Link id="GTABLE_CART" className="item-link" to="/cart">
                          {context.purchase.items.length === 0 ? '' : <div className="circle">{context.purchase.items.length}</div>}
                          <i className="icon-carrito cart-icon" />
                        </Link>
                      ) : (
                        <Link id="GTABLE_CART" className="item-link" to="/cart">
                          <i className="icon-carrito cart-icon" />
                        </Link>
                      )
                    }}
                  </AuthContext.Consumer>
                </StyledItemMenu>
              </StyledList>
            </MenuContainer>
          </div>
        </StyledContainer>
      </StyledHeader>
    )
  }
}

export default Header
